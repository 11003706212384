var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', [_c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          attrs: {
            "data-cy": "titleCourse"
          }
        }, [_vm._v("Klientkonto kurs")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-autocomplete', {
          staticClass: "mt-2 pt-1 mr-5",
          attrs: {
            "items": _vm.groupByItems,
            "item-text": "text",
            "item-value": "value",
            "label": "Grupperinger",
            "single-line": "",
            "clear-icon": "mdi-arrow-left",
            "dense": ""
          },
          model: {
            value: _vm.currentGroupBy,
            callback: function callback($$v) {
              _vm.currentGroupBy = $$v;
            },
            expression: "currentGroupBy"
          }
        }), _c('v-text-field', {
          staticClass: "mt-2 pt-1 mr-5",
          attrs: {
            "data-cy": "searchCourseList",
            "append-icon": "mdi-magnify",
            "label": "Søk i kurs",
            "single-line": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.courses,
      "search": _vm.search,
      "filter": _vm.filter,
      "sort-by": "accountingDepartmentName",
      "sort-desc": true,
      "multiSort": false,
      "group-by": _vm.currentGroupBy || null
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "openCourse"
                },
                on: {
                  "click": function click($event) {
                    return _vm.navigateToCoursePage(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Åpne kurs")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "openCourse"
                },
                on: {
                  "click": function click($event) {
                    return _vm.router.push("/kurset/".concat(item.id, "/invoiceBasis"));
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-file-document ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Gå til fakturagrunnlag")])])];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatYear(value) : "") + " ")];
      }
    }, {
      key: "item.isSentToErp",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }