
import { api } from "@/api/api";
import { ApiGetClientAccountCourseDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { getYear } from "date-fns";
import { VTextField } from "vuetify/lib";

export default defineComponent({
  name: "ClientAccountCourseListPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  setup() {
    const router = useRouter();
    const currentGroupBy = ref("");
    const courses = ref<ApiGetClientAccountCourseDto[]>([]);

    const search = ref<string>("");

    const navigateToCoursePage = (courseId: number) => {
      router.push({
        name: SingleCourseRouteNames.CourseDashboard,
        params: {
          id: courseId.toString(),
        },
      });
    };

    const getCourses = async () => (await api.clientaccount.getClientAccountCourses()).data;

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        courses.value = await getCourses();
      });
    });

    const groupByItems = [
      { text: "År", value: "endDate" },
      { text: "Arrangør", value: "organizerOrganizationName" },
      { text: "Status", value: "status" },
      { text: "Sendt til regnskap", value: "isSentToErp", filter: true },
    ];

    return {
      courses,
      navigateToCoursePage,
      headers,
      filter,
      search,
      formatYear: (date: string) => getYear(new Date(date)),
      groupByItems,
      currentGroupBy,
      router,
    };
  },
});

const filter = [
  {
    component: VTextField,
    value: "endDate",
    default: "",
    attrs: {
      label: "År",
      dense: true,
      hideDetails: true,
      clearable: true,
    },
    apply: (value: any, model: any) => {
      if (model) {
        const valueYear = getYear(new Date(value));
        return valueYear === parseInt(model);
      }
      return true;
    },
  },
];

const headers = [
  { text: "ID", value: "id" },
  { text: "Handlinger", value: "actions", sortable: false },
  { text: "Produkt ID", value: "erpProductCode" },
  { text: "Kursnavn", value: "courseName" },
  { text: "År", value: "endDate" },
  { text: "Regnskapavdeling", value: "accountingDepartmentName" },
  {
    text: "Arrangør",
    value: "organizerOrganizationName",
    filter: true,
  },
  {
    text: "Status",
    value: "status",
    filter: true,
  },
  { text: "Tilskuddskode", value: "grantId" },
  { text: "Beløp", value: "invoiceTotal" },
];
